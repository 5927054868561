import { ComponentPublicInstance } from "vue";
import { ElMessageBox } from "element-plus";
import RouterError from "../FPKG-20000-Util/const/error/routerError";
import VueError from "../FPKG-20000-Util/const/error/vueError";

// TODO: "240003": "錢包點數不足", 送去儲值
// "280008": "餘額不足  (280008)",

/** 通用 Error，會將使用者趕去登入頁 */
const toLoginPageError = [10015, 30002];
// const toLoginPageError = [10015, 10020, 50003, 50004];
// TODO: 500x: 帳號相關錯誤 實際未知

/** 前台的錯誤例外者 */
const frontExceptionErrorCodes = [20001];
// const frontExceptionErrorCodes = [20001, 170016, 280005];
// 170016: 不可用的地區
// 280005: 儲值用銀行列表通過錯誤的 response 傳送

/** 身分未通過驗證 */
// const idUnchecked = [300019, 300021];

/** 銀行未通過驗證 */
// const bankUnchecked = [300020, 300022];

const errorHandler = (
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string,
) => {
  if (instance === null) return;
  const $i18n: any = instance?.$i18n;
  const $t = $i18n.global.t;
  const $te = $i18n.global.te;
  const VUE: any = instance;

  /** vue 內建Error 與 router error 處置 */
  if (
    (err instanceof VueError || err instanceof RouterError) &&
    err.message.length
  ) {
    ElMessageBox.alert(err.message);
    return;
  }

  /** 後端報錯的處理 */
  if (isBackendError(err as { data: BackendResponse })) {
    backendErrorHandler(err as { data: BackendResponse });
    return;
  }

  /** 純數字型態的處理 */
  if (typeof err === "number") {
    numberHandle(err);
    ElMessageBox.alert(showErrorMsg(err));
    return;
  }
  /** 出現怪異的條件，直接拋出 */
  throw err;

  /** 後端回報錯誤的處置 */
  function backendErrorHandler(err: { data: BackendResponse }) {
    const res = err as { data: BackendResponse };
    numberHandle(res.data.code);
    const result = showLocaleMsg(res.data.code);
    /** 有語系顯示語系，不然就顯示 response */
    const msg = result ? result : `${res.data.response}(${err.data.code})`;
    ElMessageBox.alert(msg);
  }

  /**
   * 針對 ErrorCode 是 number 的 處理
   * @param code number
   */
  function numberHandle(code: number) {
    if (toLoginPageError.includes(code) && instance !== null) {
      VUE.$store.dispatch("account/setLogin", false);
      instance.$router.replace("/login");
    }
    // if (code === 170016 && instance !== null) {
    //   VUE.$store.dispatch("account/setLogin", false);
    //   instance.$router.replace("/regionError");
    //   return;
    // }

    const account = localStorage.getItem("verification-acount");
    // if (code === 140039 && instance !== null) {
    //   /** 信箱驗證尚未通過，轉至驗證頁 */
    //   instance.$router.push(`/register/mail?account=${account}`);
    // }
    if (code === 170021 && instance !== null) {
      /** 手機驗證尚未通過，轉至驗證頁 */
      instance.$router.push(`/smsVerify/${account}`);
    }
    // if (idUnchecked.includes(code) && instance !== null) {
    //   instance.$router.push("/personalInfo");
    // }
    // if (bankUnchecked.includes(code) && instance !== null) {
    //   instance.$router.push({
    //     path: "tradeAccount",
    //     query: { create: "true" },
    //   });
    // }

    /** 例外項目，有額外的控制或是不需要顯示錯誤訊息 */
    if (frontExceptionErrorCodes.includes(code)) return;
  }

  /** 嘗試取得錯誤碼語系，失敗即回傳 void */
  function showLocaleMsg(code: number) {
    /** 指定的語系包 */
    const LOCALE_MODULES = [
      "CasinoCore",
      "CasinoFrontApi",
      "CasinoFrontPaymentFlow",
      "CasinoFrontGames",
      "Util",
    ];
    /** 確認是否存在語系，有找到就回傳該模組 */
    const locale = LOCALE_MODULES.find(MODULE => $te(`${MODULE}.${code}`));
    if (locale) return $t(`${locale}.${code}`) as string;
  }

  /** 取得錯誤訊息，若無語系，則會顯示 "出現錯誤(code)" */
  function showErrorMsg(code: number) {
    const msg = showLocaleMsg(code);
    return msg ? msg : $t("Util.error", { code });
  }
};

/** 檢測是否為後端回傳報錯 */
function isBackendError(obj: { data: BackendResponse }) {
  return obj && typeof obj.data?.code === "number" && obj.data?.code !== 0;
}

export default errorHandler;
